import { useLazyQuery } from "@apollo/client"
import { DataFunc, SuggestionDataItem } from "react-mentions"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { Group, User } from "~/__generated__/graphql"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"

export type CustomDataItem = SuggestionDataItem & {
  user?: Pick<
    User,
    "id" | "photoUrl" | "firstName" | "lastName" | "coach" | "admin" | "role"
  >
  group?: Pick<Group, "id" | "slug">
}

export const useMentionDataSource = ({
  mentionsDisabled,
}: { mentionsDisabled?: boolean } = {}) => {
  const { currentUser } = useCurrentUserMaybe()
  const [getMentionUsers] = useLazyQuery(MENTION_USER_QUERY_DOCUMENT, {
    variables: { excludeSelf: true },
  })

  const fetchMentionables: DataFunc = async (query, callback) => {
    if (!query || mentionsDisabled) return

    const { data, error } = await getMentionUsers({ variables: { query } })

    if (error) {
      callback([])
    } else {
      invariant(data)
      const dataItems = ([] as CustomDataItem[]).concat(
        currentUser?.admin
          ? data.groups.nodes.map((g) => ({
              id: `g:${g.id}`,
              display: g.slug,
              group: g,
            }))
          : [],
        data.users.nodes
          .filter((u) => u.name && u.name.length > 0)
          .map((u) => ({
            id: u.id,
            display: u.name || " ",
            user: u,
          }))
      )
      callback(dataItems)
    }
  }

  return { fetchMentionables }
}

export const MENTION_USER_QUERY_DOCUMENT = gql(`
  query MentionUsers($query: String, $excludeSelf: Boolean) {
    users(
      query: $query,
      excludeSelf: $excludeSelf,
      fitOnly: true,
      activeOnly: true,
      onboardedOnly: true,
      first: 20
    ) {
      nodes {
        ...User_Avatar
      }
    }

    groups(
      search: $query,
      active: true,
      first: 3
    ) {
      nodes {
        id
        slug
      }
    }
  }
`)
