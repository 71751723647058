import toast from "react-hot-toast"
import { buildUrl } from "~/common/buildUrl"
import { postPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { POST_INCREMENT_SHARE_COUNT_MUTATION } from "./PostCard"

export const useSharePost = () => {
  const [incrementShareCount] = useSafeMutation(
    POST_INCREMENT_SHARE_COUNT_MUTATION
  )

  return async (post: { id: string }) => {
    const postUrl = buildUrl(postPath({ postId: post.id }))
    navigator.clipboard.writeText(postUrl)
    await incrementShareCount({ variables: { input: { postId: post.id } } })
    toast.success("Post link copied to clipboard.")
  }
}
