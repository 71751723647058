import * as React from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { XCircle } from "lucide-react"

import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "~/lib/utils"

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

type DialogOverlayProps = React.ComponentPropsWithoutRef<
  typeof DialogPrimitive.Overlay
> & {
  darkenBackground?: boolean
}

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  DialogOverlayProps
>(({ className, darkenBackground = false, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
      {
        "bg-black/70": darkenBackground,
      }
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const dialogContentVariants = cva(
  [
    "dialog",
    "fixed left-[50%] top-[50%] z-50 grid w-full max-w-2xl translate-x-[-50%] translate-y-[-50%] gap-4",
    "duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0",
    "data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
    "data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%]",
    "data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]",
    "bg-background text-foreground",
  ].join(" "),
  {
    variants: {
      variant: {
        none: "",
        card: "bg-card text-foreground border border-slate-200 rounded-lg p-6 shadow-lg",
        letter: "bg-card text-foreground border border-slate-200 p-8 min-h-96",
        wizard:
          "bg-card text-foreground rounded-lg border border-slate-200 shadow-lg transition-all duration-300 ease-in overflow-y-auto max-w-screen md:max-w-screen w-fit max-h-screen p-0 box-content",
        gallery:
          "bg-transparent p-0 border-none rounded-none shadow-none w-fit h-fit outline-none",
      },
    },
    defaultVariants: {
      variant: "card",
    },
  }
)

interface DialogContentProps
  extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>,
    VariantProps<typeof dialogContentVariants> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  noCloseButton?: boolean
  darkenBackground?: boolean
}

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(
  (
    {
      className,
      variant,
      children,
      setOpen,
      noCloseButton,
      darkenBackground = true,
      ...props
    },
    ref
  ) => {
    const clipPathRef = React.useRef<SVGClipPathElement>(null)
    const clipPathId = React.useId()

    return (
      <DialogPortal>
        <DialogOverlay darkenBackground={darkenBackground} />
        <DialogPrimitive.Content
          ref={ref}
          {...props}
          className={cn(
            dialogContentVariants({ variant: "none" }),
            variant === "letter" ? "drop-shadow-2xl" : "",
            className
          )}
        >
          {variant === "letter" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="0"
              height="0"
              viewBox="0 0 99.62 99.96"
            >
              <defs>
                <clipPath
                  clipPathUnits="objectBoundingBox"
                  ref={clipPathRef}
                  id={clipPathId}
                >
                  <path
                    d="M 0.003 0.003
                         c 0.0035 -0.0049, 0.9713-0.0018,0.9759 0.0011
                         s 0.0102, 0.9971, 0.0176, 0.9932 -0.5199 -0.014 -0.5104 -0.0138
                         S 0.006, 0.997 0.0026, 0.9996 -0.0009 0.0075 0.0026 0.0026Z"
                  />
                </clipPath>
              </defs>
            </svg>
          )}
          <div
            className={cn(dialogContentVariants({ variant, className }))}
            style={{
              clipPath:
                variant === "letter" ? `url(#${clipPathId})` : undefined,
            }}
          >
            {children}
            {!noCloseButton && (
              <DialogPrimitive.Close
                className={cn(
                  "absolute text-slate-500 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-slate-100 data-[state=open]:text-slate-500  ",
                  variant === "letter" ? "top-6 right-6" : "top-4 right-4"
                )}
              >
                <XCircle
                  className="h-4 w-4"
                  onClick={() => {
                    setOpen && setOpen(false)
                  }}
                />
                <span className="sr-only">Close</span>
              </DialogPrimitive.Close>
            )}
          </div>
        </DialogPrimitive.Content>
      </DialogPortal>
    )
  }
)
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn("flex flex-col space-y-1.5 text-center py-4", className)}
    {...props}
  />
)
DialogHeader.displayName = "DialogHeader"

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-row justify-between items-center space-x-2",
      className
    )}
    {...props}
  />
)
DialogFooter.displayName = "DialogFooter"

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-3xl font-semibold text-foreground font-serif",
      className
    )}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogSubTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn("text-md font-normal text-foreground", className)}
    {...props}
  />
))
DialogSubTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm text-slate-500 ", className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogSubTitle,
  DialogDescription,
}
