import * as React from "react"
import * as SwitchPrimitives from "@radix-ui/react-switch"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "~/lib/utils"

const switchVariants = cva(
  "peer inline-flex w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "",
        selector:
          "w-auto relative grid place-content-center items-center data-[state=checked]:!bg-white text-black data-[state=checked]:[&>span]:bg-primary data-[state=checked]:[&>span]:text-white data-[state=unchecked]:[&>span]:bg-white data-[state=unchecked]:[&>span]:text-black",
      },
      theme: {
        default:
          "data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
        success:
          "data-[state=checked]:bg-seaGreen data-[state=unchecked]:bg-input",
        pricingTier:
          "data-[state=unchecked]:!bg-primary data-[state=unchecked]:!text-white data-[state=checked]:!bg-primary data-[state=checked]:!text-white [&>span]:!bg-white [&>span]:!text-black",
      },
      size: {
        default: "h-6",
        lg: "h-8",
        xl: "h-10",
        "2xl": "h-12",
      },
    },
    defaultVariants: {
      variant: "default",
      theme: "default",
      size: "default",
    },
  }
)

interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
    VariantProps<typeof switchVariants> {
  options?: [JSX.Element | string, JSX.Element | string]
}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(
  (
    {
      className,
      variant,
      theme,
      size = "default",
      options,
      checked,
      onCheckedChange,
      ...props
    },
    ref
  ) => (
    <SwitchPrimitives.Root
      className={cn(switchVariants({ variant, theme, size }), className)}
      {...props}
      checked={checked}
      onCheckedChange={onCheckedChange}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          "relative pointer-events-none block rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-full data-[state=unchecked]:translate-x-0",
          size === "default" && "h-5 w-5",
          size === "lg" && "h-7 w-7",
          size === "xl" && "h-9 w-9",
          size === "2xl" && "h-11 w-11",
          variant === "selector" &&
            cn(
              "grid place-content-center items-center w-fit font-normal",
              size === "default" && "p-1 text-4xs",
              size === "lg" && "p-2 text-3xs",
              size === "xl" && "p-3 text-2xs",
              size === "2xl" && "p-4 text-xs"
            )
        )}
        style={{ gridArea: "1 / 1" }}
      >
        {options?.map((option, index) => (
          <span
            key={index}
            className={cn(
              "transition-opacity",
              (checked && index === 0) || (!checked && index === 1)
                ? "opacity-0"
                : "opacity-100"
            )}
            style={{ gridArea: "1 / 1" }}
          >
            {option}
          </span>
        ))}
      </SwitchPrimitives.Thumb>
      {options && (
        <div className={cn("flex")} style={{ gridArea: "1 / 1" }}>
          {options?.map((_, outerIndex) => (
            <div
              className={cn(
                "grid place-content-center items-center text-4xs font-normal",
                size === "default" && "h-5 p-1 text-4xs",
                size === "lg" && "h-7 p-2 text-3xs",
                size === "xl" && "h-9 p-3 text-2xs",
                size === "2xl" && "h-11 p-4 text-xs"
              )}
              key={outerIndex}
            >
              {options.map((option, innerIndex) => (
                <span
                  key={`${outerIndex}-${innerIndex}`}
                  className={cn(
                    outerIndex === innerIndex ? "opacity-100" : "opacity-0"
                  )}
                  style={{ gridArea: "1 / 1" }}
                >
                  {option}
                </span>
              ))}
            </div>
          ))}
        </div>
      )}
    </SwitchPrimitives.Root>
  )
)
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
