import * as React from "react";
const SvgMarketinglandLogoSquare = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 600 600", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 600, height: 600, rx: 96, ry: 96, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("rect", { width: 600, height: 600, rx: 96, ry: 96, style: {
  fill: "#010101"
} }), /* @__PURE__ */ React.createElement("path", { d: "M214.8,433.2V166.8h64.75l20.52,144.71h1.69l19.89-144.71h64.75v266.4h-41.47v-85.88l.42-103.42h-1.48l-26.45,189.29h-33.01l-26.45-189.29h-1.9l.63,104.15v85.15h-41.89Z", style: {
  fill: "#21b8ce"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M320.82,580.15h0c41.39,0,74.95-33.55,74.95-74.95,0,41.39-33.56,74.95-74.95,74.95Z", style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M466.86,580.05c-39.6-2.01-71.09-34.76-71.09-74.85,0,40.1,31.5,72.84,71.09,74.85Z", style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "470.72 580.15 470.72 580.15 470.72 580.15 470.72 580.15 470.72 580.15", style: {
  fill: "#1fb0c8"
} }), /* @__PURE__ */ React.createElement("path", { d: "M320.82,580.15h149.89c-1.29,0-2.58-.03-3.85-.1-39.6-2.01-71.09-34.75-71.09-74.85,0,41.4-33.56,74.95-74.95,74.95Z", style: {
  fill: "#1fb0c8"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "187.58 555.17 220.89 555.17 220.89 505.2 120.95 505.2 120.95 600 154.27 600 154.27 555.17 187.58 555.17", style: {
  fill: "#1fb0c8"
} }), /* @__PURE__ */ React.createElement("path", { d: "M.02,505.2c.3,24.22,9.56,46.27,24.62,63.01l63.01-63.01H.02Z", style: {
  fill: "#d53994"
} }), /* @__PURE__ */ React.createElement("path", { d: "M87.64,505.2l-63.01,63.01c15.83,17.58,38.05,29.27,63.01,31.43v-94.43Z", style: {
  fill: "#69bd45"
} }), /* @__PURE__ */ React.createElement("path", { d: "M595.54,533c2.78-8.79,4.33-18.12,4.45-27.8h-62.65c23.47,0,44.45,10.85,58.2,27.8Z", style: {
  fill: "#1fb0c8"
} }), /* @__PURE__ */ React.createElement("path", { d: "M320.82,580.15v-74.95h-99.93v49.97c34.83,0,65.49,17.82,83.38,44.83h16.56v-19.85Z", style: {
  fill: "#d53994"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "537.34 580.15 537.34 538.51 537.34 505.2 470.72 505.2 470.72 580.15 537.34 580.15", style: {
  fill: "#d53994"
} }), /* @__PURE__ */ React.createElement("path", { d: "M537.34,580.15h-66.62v19.85h33.28c11.72,0,22.95-2.1,33.34-5.95v-13.9h0Z", style: {
  fill: "#f5d117"
} }), /* @__PURE__ */ React.createElement("rect", { x: 187.58, y: 555.17, width: 33.31, height: 44.83, style: {
  fill: "#d53994"
} }), /* @__PURE__ */ React.createElement("rect", { x: 154.27, y: 555.17, width: 33.31, height: 44.83, style: {
  fill: "#69bd45"
} }), /* @__PURE__ */ React.createElement("path", { d: "M537.34,505.2v33.31c18.24,0,33.77,11.8,39.38,28.16,8.38-9.72,14.85-21.14,18.81-33.67-13.75-16.94-34.73-27.8-58.2-27.8Z", style: {
  fill: "#f3722e"
} }), /* @__PURE__ */ React.createElement("path", { d: "M537.34,538.51v41.64h0v13.9c15.37-5.69,28.88-15.2,39.38-27.38-5.62-16.36-21.15-28.16-39.38-28.16Z", style: {
  fill: "#69bd45"
} }), /* @__PURE__ */ React.createElement("path", { d: "M220.89,555.17v44.83h83.38c-17.89-27.02-48.55-44.83-83.38-44.83Z", style: {
  fill: "#f5d117"
} }), /* @__PURE__ */ React.createElement("path", { d: "M87.64,505.2v94.43c2.75.24,5.54.37,8.36.37h24.95v-94.8h-33.31Z", style: {
  fill: "#f3722e"
} }), /* @__PURE__ */ React.createElement("path", { d: "M395.77,505.2h-74.95v74.95c41.39,0,74.95-33.56,74.95-74.95Z", style: {
  fill: "#f3722e"
} }), /* @__PURE__ */ React.createElement("path", { d: "M466.86,580.05c1.28.06,2.56.1,3.85.1h0v-74.95h-74.95c0,40.1,31.5,72.84,71.09,74.85Z", style: {
  fill: "#f3722e"
} }), /* @__PURE__ */ React.createElement("path", { d: "M470.72,580.15h-149.9c1.29,0,2.58.03,3.86.1,18.08.92,34.46,8.25,46.94,19.75h48.31c13.35-12.32,31.19-19.85,50.79-19.85,0,0,0,0,0,0h0Z", style: {
  fill: "#f5d117"
} }), /* @__PURE__ */ React.createElement("path", { d: "M320.82,580.15v19.85h50.79c-12.47-11.5-28.86-18.84-46.93-19.75-1.28-.06-2.56-.1-3.86-.1Z", style: {
  fill: "#69bd45"
} }), /* @__PURE__ */ React.createElement("path", { d: "M470.72,580.15h0s0,0,0,0c-19.6,0-37.43,7.53-50.79,19.85h50.79v-19.85h0Z", style: {
  fill: "#69bd45"
} })));
export default SvgMarketinglandLogoSquare;
