import { useNavigate } from "react-router-dom"
import { Post_CardFragment, AhoyEventTypeEnum } from "~/__generated__/graphql"
import { useComposerContext } from "~/post-composer/ComposerContext"
import EmojiPicker from "emoji-picker-react"
import { useState } from "react"
import ClickAwayListener from "react-click-away-listener"
import { useReactions } from "~/post-composer/useReactions"
import { useBookmarks } from "~/post-composer/useBookmarks"
import { feedPath, postPath } from "~/common/paths"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { usePostPinning } from "~/common/postUtils"
import { IconButton } from "~/ui/IconButton"
import { StackedUserAvatars } from "~/ui/StackedUserAvatars"
import { cn } from "~/lib/utils"
import { useCommunity } from "~/community/useCommunity"
import {
  Bookmark,
  MessageSquare,
  Pin,
  Repeat,
  Share,
  Smile,
} from "lucide-react"
import { useSharePost } from "./useSharePost"

export const PostActions = ({
  post,
  isReply,
  replyDisabled,
  replyToReply,
  onSinglePostPage,
  onBookmarkToggle,
}: {
  post: Post_CardFragment
  isReply: boolean
  replyDisabled: boolean
  replyToReply?: () => void
  onSinglePostPage?: boolean
  onBookmarkToggle?: (bookmarkExists: boolean) => void
}) => {
  const { logEvent } = useLogEvent()
  const { currentUser } = useCurrentUser()
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false)
  const { composerRef } = useComposerContext() || {}
  const { addReaction } = useReactions({ postId: post.id })
  const { bookmark, toggleBookmark } = useBookmarks({ post: post })
  const sharePost = useSharePost()
  const community = useCommunity()

  const navigate = useNavigate()
  const pinPost = usePostPinning()
  const navigateToReply = () =>
    navigate(`${postPath({ postId: post.id })}?reply=t`)

  return (
    <div className="flex flex-wrap items-center mt-1 gap-2 w-full self-start justify-between">
      <div className="relative">
        <IconButton
          className="card-clickable"
          variant="postAction"
          onClick={() => {
            if (!emojiPickerOpen) {
              setEmojiPickerOpen(true)
            }
          }}
        >
          <Smile className="w-4 h-4" strokeWidth={1.5} />
        </IconButton>
        {emojiPickerOpen && (
          <ClickAwayListener onClickAway={() => setEmojiPickerOpen(false)}>
            <div className="absolute top-[20px] left-[5px] z-[1000]">
              <EmojiPicker
                reactionsDefaultOpen
                allowExpandReactions
                searchDisabled
                skinTonesDisabled
                lazyLoadEmojis
                reactions={community.defaultReactions}
                previewConfig={{ showPreview: false }}
                onEmojiClick={(emoji) => {
                  addReaction(emoji.emoji)
                  logEvent(AhoyEventTypeEnum.PostReactedTo, {
                    emoji_name: emoji.emoji,
                    post_id: post.id,
                  })
                  setEmojiPickerOpen(false)
                }}
              />
            </div>
          </ClickAwayListener>
        )}
      </div>

      {!isReply && (
        <div className="flex items-center gap-2">
          <IconButton
            onClick={navigateToReply}
            size="expandableIcon"
            variant="postAction"
          >
            <MessageSquare className="w-4 h-4" strokeWidth={1.5} />
            {post.repliesCount && post.repliesCount > 0 ? (
              <span className="ml-1">{post.repliesCount}</span>
            ) : null}
          </IconButton>
          <StackedUserAvatars
            users={post.orderedCommenters}
            totalCount={post.commentersCount}
            avatarSize="smaller"
            onClick={navigateToReply}
            showCount={4}
            className="card-clickable cursor-pointer"
          />
        </div>
      )}

      {!isReply && (
        <IconButton
          variant="postAction"
          size="expandableIcon"
          onClick={() => {
            if (onSinglePostPage) {
              navigate(
                feedPath.pattern + `?post=focus&share_post_id=${post.id}`
              )
            } else {
              logEvent(AhoyEventTypeEnum.ContentShared, {
                content_id: post.id,
              })
              composerRef?.current?.retweetPost(post.id)
            }
          }}
        >
          <Repeat className="w-4 h-4" strokeWidth={1.5} />
          {post.retweetersCount && post.retweetersCount > 0 ? (
            <span className="ml-1">{post.retweetersCount}</span>
          ) : null}
        </IconButton>
      )}

      {!isReply && (
        <IconButton
          variant="postAction"
          size="expandableIcon"
          onClick={() => sharePost(post)}
        >
          <Share className="w-4 h-4" strokeWidth={1.5} />
        </IconButton>
      )}

      {!isReply && (
        <div className="flex items-center">
          <IconButton
            className="card-clickable"
            onClick={async () => {
              const bookmarkExists = await toggleBookmark()
              if (onBookmarkToggle) {
                onBookmarkToggle(bookmarkExists)
              }
            }}
          >
            <Bookmark
              strokeWidth={1.5}
              className={cn(
                "w-4 h-4",
                bookmark && "fill-highlight text-highlight"
              )}
            />
          </IconButton>
          {post.pinnable && post.currentUserCanPin.value && (
            <IconButton
              onClick={() => pinPost(post.id)}
              className="card-clickable"
            >
              <Pin
                strokeWidth={1.5}
                className={cn(
                  "w-4 h-4",
                  currentUser.pinnedPostId === post.id &&
                    "fill-highlight text-highlight"
                )}
              />
            </IconButton>
          )}
        </div>
      )}

      {isReply && replyToReply && !replyDisabled && (
        <div
          className={cn(
            "cursor-pointer p-2 text-2xs text-foreground tracking-wide card-clickable text-foreground"
          )}
          onClick={replyToReply}
        >
          Reply
        </div>
      )}
    </div>
  )
}
