import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "~/lib/utils"
import { Link, LinkProps } from "react-router-dom"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap no-underline text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 rounded-full no-underline hover:no-underline",
  {
    variants: {
      variant: {
        success: "bg-green-500 text-slate-50 hover:bg-green-500/90",
        default: "bg-primary text-white hover:bg-primary/90 hover:text-white",
        green: "bg-seaGreen text-white hover:bg-seaGreen/90 hover:text-white",
        destructive: "bg-red-500 text-slate-50 hover:bg-red-500/90",
        outline: "border border-primary text-primary hover:bg-white",
        secondary: "bg-slate-100 text-slate-900 hover:bg-slate-100/80",
        secondaryDark:
          "bg-pretext-light-gray text-white hover:bg-pretext-light-gray100/80",
        ghost: "text-primary bg-white hover:bg-slate-100 hover:text-primary",
        invisible: "text-primary bg-transparent hover:bg-transparent",
        link: "text-foreground underline-offset-4 hover:underline ",
        empty: "text-placeholder hover:text-primary",
        subtle:
          "text-primary bg-white hover:bg-slate-100 hover:text-primary border-0",
        input: [
          "flex h-10 w-full items-center justify-between rounded-md border border-slate-200 bg-white px-3 py-2 text-sm",
          "ring-offset-white placeholder:text-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-950",
          "focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 ",
          "    ",
        ].join(" "),
        onboarding:
          "bg-onboarding-primary text-onboarding-primary-foreground border-onboarding-primary border hover:bg-onboarding-primary/80",
        backgroundOutline:
          "bg-background border border-background-button-outline text-background-button-outline",
        onboardingBackgroundOutline:
          "bg-background border border-onboarding-background-button-outline text-onboarding-background-button-outline",
        postAction:
          "bg-card border border-mercury text-black hover:bg-primary/90 hover:text-white card-clickable flex items-center justify-center gap-1 text-2xs",
        minimal:
          "text-primary text-xs font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 py-0 p-0",
      },
      size: {
        default: "px-4 py-2",
        tight: "py-0 px-y",
        sm: "py-1 px-3",
        lg: "py-2 px-8",
        icon: "h-8 w-8",
        expandableIcon: "h-8 min-w-8 p-2",
        inline: "py-0 px-0 w-fit h-fit",
      },
      pseudo: {
        default: "",
        disabled: "opacity-50",
      },
      modifiers: {
        inputHover: "hover:border-slate-300 hover:bg-slate-100",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      pseudo: "default",
    },
  }
)

export type ButtonVariants = VariantProps<typeof buttonVariants>

type SharedProps = {
  isLoading?: boolean
}

export interface ButtonProps
  extends SharedProps,
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonVariants {
  asChild?: boolean
  pre?: React.ReactElement
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      pseudo,
      modifiers,
      isLoading,
      children,
      pre,
      asChild = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, pseudo, modifiers, className }),
          pre && "flex gap-2"
        )}
        ref={ref}
        type="button"
        {...props}
      >
        {pre ? (
          <>
            {pre}
            <span>{isLoading ? "Saving..." : children}</span>
          </>
        ) : (
          <>{isLoading ? "Saving..." : children}</>
        )}
      </Comp>
    )
  }
)
Button.displayName = "Button"

export interface LinkButtonProps
  extends SharedProps,
    LinkProps,
    VariantProps<typeof buttonVariants> {}

const LinkButton = ({
  className,
  variant,
  size,
  isLoading,
  children,
  ...props
}: LinkButtonProps) => {
  return (
    <Link
      className={cn(buttonVariants({ variant, size, className }))}
      {...props}
    >
      {isLoading ? "Saving..." : children}
    </Link>
  )
}
LinkButton.displayName = "LinkButton"

export interface FakeButtonProps
  extends SharedProps,
    React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof buttonVariants> {}

const FakeButton = ({
  className,
  variant,
  size,
  isLoading,
  children,
  ...props
}: FakeButtonProps) => {
  return (
    <div className={cn(buttonVariants({ variant, size, className }))}>
      {isLoading ? "Saving..." : children}
    </div>
  )
}
FakeButton.displayName = "FakeButton"

export { Button, buttonVariants, LinkButton, FakeButton }
