import { Button, ButtonVariants } from "~/ui/button"
import { useMergeContext } from "./MergeContext"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"

export const MergeLinkButton = ({
  onExitCallback,
  showManualSetup = true,
  onClick,
  children,
  className,
  variant = "ghost",
  size = "default",
}: {
  children?: React.ReactNode
  showManualSetup?: boolean
  onClick?: () => void
  onExitCallback?: (success: boolean | undefined) => void
  className?: string
  variant?: ButtonVariants["variant"]
  size?: ButtonVariants["size"]
}) => {
  const { openMergeDialog } = useMergeContext()
  const { logEvent } = useLogEvent()

  return (
    <div className="cursor-pointer">
      {children ? (
        <div
          onClick={() => {
            logEvent(AhoyEventTypeEnum.BenchmarkCtaClicked)
            onClick?.()
            openMergeDialog({ showManualSetup, onExitCallback })
          }}
        >
          {children}
        </div>
      ) : (
        <Button
          className={className}
          variant={variant}
          size={size}
          onClick={() => {
            logEvent(AhoyEventTypeEnum.BenchmarkCtaClicked)
            onClick?.()
            openMergeDialog({ showManualSetup, onExitCallback })
          }}
        >
          Connect HRIS
        </Button>
      )}
    </div>
  )
}
