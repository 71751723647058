import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { MergeLinkButton } from "~/merge/MergeLinkButton"
import { Outlet, matchPath, useLocation } from "react-router-dom"
import {
  articlePath,
  benchmarkPath,
  directoryPath,
  editProfilePath,
  feedPath,
  stripePortalPath,
  eventsPath,
} from "~/common/paths"
import { useCurrentUser } from "~/auth/CurrentUserContext"

import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { LinkButton } from "~/ui/button"

import DialogClose from "~/images/icons/dialog-close.svg?react"
import { AhoyEventTypeEnum, CommunitySlug } from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { PAST_DUE_STATUSES } from "~/common/stripe"
import { Alert, AlertDescription, AlertTitle } from "~/ui/alert"
import { Link } from "~/ui/Link"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import CreditCards from "../images/credit-cards@2x.png"
import { IconButton } from "~/ui/IconButton"
import { cn } from "~/lib/utils"

const dismissalKey = (key: string) => `CTA::DISMISSAL::${key}`
const ctaDismissed = (key: string) => !!localStorage.getItem(dismissalKey(key))

const buttonVariant = (slug: CommunitySlug) =>
  slug === CommunitySlug.Marketingland ? "default" : "ghost"

const NewCourseAnnouncementCTA = () => {
  const { currentUser } = useCurrentUser()
  const { logEvent } = useLogEvent()
  const course = currentUser.newCourseAnnouncement
  const community = useCommunity()

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (e.target instanceof HTMLAnchorElement) {
        logEvent(AhoyEventTypeEnum.BannerLinkClicked, {
          url_clicked: e.target.href,
        })
      }
    },
    [logEvent]
  )

  if (!course) return null

  return (
    <div className="py-1 flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-4 lg:w-[700px] tracking-[0.5px]">
      <div className="flex-1">
        <div className="font-semibold text-[15px]">
          {course.approvedRevision?.title} is live!
        </div>
        <div className="text-2xs mt-[2px] sm:mt-1">
          Bookmark the course, take it on your own pace. Oh, and it's included
          with plus or pro memberships at no additional cost!
        </div>
      </div>
      <LinkButton
        to={articlePath({ articleId: course.id })}
        onClick={handleClick}
        variant={buttonVariant(community.slug)}
      >
        View Course
      </LinkButton>
    </div>
  )
}
const BenchmarkCTA = () => {
  const { logEvent } = useLogEvent()
  const handleClick = useCallback(() => {
    logEvent(AhoyEventTypeEnum.BannerLinkClicked, {
      url_clicked: window.location.href,
    })
  }, [logEvent])

  return (
    <div className="py-1 flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-4 lg:w-[700px] tracking-[0.5px]">
      <div className="flex-1">
        <div className="font-semibold text-[15px]">
          Connect your HRIS account!
        </div>
        <div className="text-2xs mt-[2px] sm:mt-1">
          Connect with to compare your metrics and see your performance metrics!
        </div>
      </div>
      <MergeLinkButton variant="ghost" onClick={handleClick} />
    </div>
  )
}

const DirectoryCTA = () => {
  const { currentUser } = useCurrentUser()
  const { logEvent } = useLogEvent()
  const community = useCommunity()

  return (
    <div className="flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-4 lg:w-[700px] tracking-[0.5px]">
      <AvatarWithFallback
        user={currentUser}
        size="cta"
        noBorder={true}
        className="scale-50 h-[24px] w-[24px] sm:scale-100 sm:w-auto sm:h-auto"
      />
      <div className="flex-1">
        <div className="font-semibold text-[15px]">
          Complete your profile and get noticed!
        </div>
        <div className="text-2xs mt-[2px] sm:mt-1">
          Your profile is 85% complete! Tell us a little more about you.
        </div>
      </div>
      <LinkButton
        to={editProfilePath.pattern}
        variant={buttonVariant(community.slug)}
        className="text-2xs py-3 max-w-[147px]"
        onClick={(e: React.MouseEvent) => {
          if (e.target instanceof HTMLAnchorElement) {
            logEvent(AhoyEventTypeEnum.BannerLinkClicked, {
              url_clicked: e.target.href,
            })
          }
          logEvent(AhoyEventTypeEnum.CompleteProfileCtaClicked)
        }}
      >
        Complete Profile
      </LinkButton>
    </div>
  )
}

const SummitCTA = () => {
  const { logEvent } = useLogEvent()
  const community = useCommunity()

  return (
    <div className="flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-4 lg:w-[700px] tracking-[0.5px]">
      <div className="flex-1">
        <div className="font-semibold text-[15px]">
          {community.summit.title}
        </div>
        <div className="text-2xs mt-[2px] sm:mt-1">
          {community.summit.description}
        </div>
      </div>
      <LinkButton
        to={community.summit.cta.url}
        variant={buttonVariant(community.slug)}
        className="text-2xs py-3 max-w-[147px]"
        onClick={(e: React.MouseEvent) => {
          if (e.target instanceof HTMLAnchorElement) {
            logEvent(AhoyEventTypeEnum.BannerLinkClicked, {
              url_clicked: e.target.href,
            })
          }
          logEvent(AhoyEventTypeEnum.SummitCtaClicked, {
            url_clicked:
              e.target instanceof HTMLAnchorElement
                ? e.target.href
                : "click_event_not_an_anchor_tag",
          })
        }}
      >
        {community.summit.cta.text}
      </LinkButton>
    </div>
  )
}

const OverdueCTA = () => {
  const { logEvent } = useLogEvent()
  const community = useCommunity()

  const handlePastDueLinkClicked = useCallback(
    (e: React.MouseEvent) => {
      if (e.target instanceof HTMLAnchorElement) {
        logEvent(AhoyEventTypeEnum.BannerLinkClicked, {
          url_clicked: e.target.href,
        })
      }
    },
    [logEvent]
  )

  return (
    <>
      <div>
        <img src={CreditCards} alt="Credit Cards" className="w-[44px]" />
      </div>
      <div className="text-center">
        <AlertTitle>
          Whoops! We were unable to verify your payment method to renew your
          membership.
        </AlertTitle>
        <AlertDescription>
          Please{" "}
          <Link
            href={stripePortalPath.pattern}
            method="POST"
            requestBody={{
              return_url: window.location.href,
              flow_type: "payment_method_update",
            }}
            onClick={handlePastDueLinkClicked}
            className="underline text-white hover:text-white/80"
          >
            update your payment method
          </Link>{" "}
          to continue accessing {community.name}.
        </AlertDescription>
      </div>
    </>
  )
}

const CTAWrapper = ({
  dismissable,
  permanentDismissalKey,
  children,
  onDismiss,
}: {
  dismissable: boolean
  permanentDismissalKey: string | null
  children: ReactNode
  onDismiss: (key: string | null) => void
}) => {
  const ccls = useCommunityClassname()

  return (
    <Alert
      variant="cta"
      className={cn(
        "items-center mb-4 rounded-none",
        ccls({
          [CommunitySlug.Marketingland]: "bg-[#FFAF2F] text-black",
          default: "bg-highlight",
        })
      )}
    >
      {children}
      {dismissable && (
        <IconButton
          onClick={() => onDismiss(permanentDismissalKey)}
          className="bg-transparent ml-4"
        >
          <DialogClose />
        </IconButton>
      )}
    </Alert>
  )
}

export const CTALayout = ({ children }: { children?: React.ReactNode }) => {
  const { pathname } = useLocation()
  const { currentUser } = useCurrentUser()
  const community = useCommunity()

  const isPastDue = useMemo(
    () =>
      PAST_DUE_STATUSES.includes(
        currentUser.activeStripeSubscription?.status || ""
      ),
    [currentUser.activeStripeSubscription]
  )

  const displaySummitCTA = useMemo(() => {
    return community.summit.display
  }, [community])

  const [cta, setCTA] = useState<ReactNode | null>()
  const [ctaIsDismissable, setCtaIsDismissable] = useState(true)
  const [permanentDismissalKey, setPermanentDismissalKey] = useState<
    string | null
  >(null)

  const onDismiss = (permanentDismissalKey: string | null) => {
    setCTA(null)
    if (permanentDismissalKey) {
      localStorage.setItem(dismissalKey(permanentDismissalKey), "t")
    }
  }

  useEffect(() => {
    if (isPastDue) {
      setCTA(<OverdueCTA />)
      setCtaIsDismissable(false)
      return
    }

    if (
      matchPath({ path: benchmarkPath.pattern }, pathname) &&
      !currentUser.hrisIntegration &&
      !currentUser.manualHrisData
    ) {
      setCtaIsDismissable(false)
      setCTA(<BenchmarkCTA />)
    } else if (
      matchPath({ path: directoryPath.pattern }, pathname) &&
      (!currentUser.photoUrl ||
        (!currentUser.linkedin &&
          !currentUser.twitter &&
          !currentUser.instagram))
    ) {
      setCTA(<DirectoryCTA />)
    } else if (
      matchPath({ path: feedPath.pattern }, pathname) &&
      currentUser.newCourseAnnouncement &&
      !ctaDismissed(currentUser.newCourseAnnouncement.id)
    ) {
      setPermanentDismissalKey(currentUser.newCourseAnnouncement.id)
      setCTA(<NewCourseAnnouncementCTA />)
    } else if (
      matchPath({ path: eventsPath.pattern }, pathname) &&
      displaySummitCTA
    ) {
      setCtaIsDismissable(false)
      setCTA(<SummitCTA />)
    } else {
      setCTA(null)
    }
  }, [pathname, currentUser, isPastDue, displaySummitCTA])

  return (
    <>
      {cta && (
        <CTAWrapper
          onDismiss={onDismiss}
          dismissable={ctaIsDismissable}
          permanentDismissalKey={permanentDismissalKey}
        >
          {cta}
        </CTAWrapper>
      )}
      {children ? children : <Outlet />}
    </>
  )
}
