import { userProfilePath } from "~/common/paths"
import React, { Fragment, useCallback, useMemo } from "react"
import { useUserDialogContext } from "~/directory/UserDialogContext"
import { Link } from "~/ui/Link"
import { UserName } from "~/directory/UserName"

const breakWords = (text: string) => {
  const mentionMatches: string[] = text.match(/@\[[^\]]+\]\([^)]+\)/g) || []
  if (mentionMatches.length === 0) return text.split(" ")

  let reformattedText = text
  mentionMatches
    .filter((mention, index) => mentionMatches.indexOf(mention) === index)
    .forEach((mention) => {
      reformattedText = reformattedText.replace(
        mention,
        mention.replace(/ /g, "+")
      )
    })

  return reformattedText.split(" ")
}

const isEmail = (text: string) =>
  !!text.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)

export const isLink = (text: string) =>
  (text.match(/^https?:\/\/\S+/) ||
    text.match(/^www\.\S+\.\S+/) ||
    text.match(/^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}/)) &&
  !isEmail(text) &&
  getURL(text)
const isInternalLink = (text: string) => {
  if (!isLink(text)) return false

  const url = getURL(text)
  if (!url) return false
  return url.hostname === window.location.hostname
}
const isMention = (text: string) => text.match(/@\[[^\]]+\]\([^)]+\)/)
const isGroupMention = (text: string) => isMention(text) && text.match(/\(g:/)
const isWord = (text: string) =>
  !isLink(text) && !isMention(text) && !isEmail(text)

export const getURL = (text: string) => {
  let url: URL | null = null
  try {
    url = new URL(text)
    if (!url.protocol.startsWith("http")) throw new Error()
  } catch {
    try {
      url = new URL("https://" + text)
    } catch {
      return null
    }
  }
  return url
}

const link = (text: string) => {
  const url = getURL(text)
  if (!url) return null
  return {
    display:
      url.host +
      (url.pathname === "/" ? "" : url.pathname) +
      (url.search === "" ? "" : url.search),
    url: url.href,
  }
}

const mention = (text: string) => {
  const [, name, id] = text.match(/@\[(.*?)\]\((.*?)\)/)!
  return { name: name.replaceAll("+", " "), id }
}

type FormattedContentProps = {
  content: string
  onLinkClicked?: (url: string) => void
  withLinks?: boolean
}

export const FormattedContent = ({
  content,
  onLinkClicked,
  withLinks = true,
}: FormattedContentProps) => {
  const { openUserDialog } = useUserDialogContext()
  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      onLinkClicked && onLinkClicked(e.currentTarget.href)
    },
    [onLinkClicked]
  )

  const lines = useMemo(() => (content ? content.split("\n") : []), [content])

  if (!content) return null
  return (
    <>
      {lines.map((line, index) => (
        <Fragment key={index}>
          {breakWords(line).map((word, wordIndex) => (
            <Fragment key={wordIndex}>
              {isEmail(word) &&
                (withLinks ? (
                  <Link
                    to={`mailto:${word}`}
                    onClick={handleLinkClick}
                    className="card-clickable"
                  >
                    {word}
                  </Link>
                ) : (
                  word
                ))}
              {isLink(word) && (
                <LinkDisplay
                  word={word}
                  withLinks={withLinks}
                  handleLinkClick={handleLinkClick}
                />
              )}
              {isMention(word) && (
                <>
                  {isGroupMention(word) ? (
                    <span className="font-semibold">@{mention(word).name}</span>
                  ) : (
                    <>
                      {withLinks ? (
                        <Link
                          to={userProfilePath({ id: mention(word).id })}
                          onClick={(e) => {
                            e.preventDefault()
                            openUserDialog(mention(word).id)
                          }}
                          className="font-semibold card-clickable hover:underline nostyle text-foreground no-underline"
                        >
                          @<UserName user={mention(word)} />
                        </Link>
                      ) : (
                        <>
                          @<UserName user={mention(word)} />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {isWord(word) && word}{" "}
            </Fragment>
          ))}
          {index !== lines.length - 1 && <br />}
        </Fragment>
      ))}
    </>
  )
}

const LinkDisplay = ({
  withLinks,
  word,
  handleLinkClick,
}: {
  withLinks: boolean
  word: string
  handleLinkClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
}) => {
  const parsedLink = link(word)
  if (!parsedLink) return <span>{word}</span>

  return (
    <>
      {withLinks ? (
        <>
          {isInternalLink(word) ? (
            <Link
              to={link(word)!.url}
              onClick={handleLinkClick}
              className="card-clickable"
            >
              {parsedLink.display}
            </Link>
          ) : (
            <Link
              href={parsedLink.url}
              target="_blank"
              rel="nofollow noopener noreferrer"
              onClick={handleLinkClick}
              className="card-clickable"
            >
              {parsedLink.display}
            </Link>
          )}
        </>
      ) : (
        <>{parsedLink.display}</>
      )}
    </>
  )
}
