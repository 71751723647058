import { gql } from "~/__generated__"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { useLogEvent } from "~/analytics/EventsContext"

export const useUpvotes = ({
  postId,
  articleId,
}: {
  postId?: string
  articleId?: string
}) => {
  const [runUpvoteCreate] = useSafeMutation(UPVOTE_CREATE_MUTATION)
  const { logEvent } = useLogEvent()

  const addUpvote = async () => {
    const { data, errors } = await runUpvoteCreate({
      variables: {
        input: {
          postId,
          articleId,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      logEvent(AhoyEventTypeEnum.ContentLiked, {
        type: postId ? "post" : "article",
        content_id: postId || articleId,
        upvote_count: data?.upvoteCreate.upvoteCount,
      })
    }
  }

  const [runUpvoteDestroy] = useSafeMutation(UPVOTE_DESTROY_MUTATION)

  const removeUpvote = async () => {
    const { errors, data } = await runUpvoteDestroy({
      variables: {
        input: {
          postId,
          articleId,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      logEvent(AhoyEventTypeEnum.ContentUnliked, {
        type: postId ? "post" : "article",
        content_id: postId || articleId,
        upvote_count: data?.upvoteDestroy.upvoteCount,
      })
    }
  }

  return { addUpvote, removeUpvote }
}

const UPVOTE_CREATE_MUTATION = gql(`
  mutation UpvoteCreate($input: UpvoteCreateInput!) {
    upvoteCreate(input: $input) {
      upvoteCount
      post {
        ...Post_Card
      }
      article {
        id
        currentUserUpvoted
        upvotesCount
      }
    }
  }
`)

const UPVOTE_DESTROY_MUTATION = gql(`
  mutation UpvoteDestroy($input: UpvoteDestroyInput!) {
    upvoteDestroy(input: $input) {
      upvoteCount
      post {
        ...Post_Card
      }
      article {
        id
        currentUserUpvoted
        upvotesCount
      }
    }
  }
`)
