import React, { createContext, useCallback, useContext, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { ManualHrisDataProvider } from "./manualData"
import { MergeDialog } from "./MergeDialog"

interface MergeContextType {
  openMergeDialog: (options?: {
    showManualSetup?: boolean
    onExitCallback?: (success: boolean | undefined) => void
  }) => void
  closeMergeDialog: () => void
}

const MergeContext = createContext<MergeContextType>({
  openMergeDialog: () => {},
  closeMergeDialog: () => {},
})

export const useMergeContext = () => {
  return useContext(MergeContext)
}

export const MergeContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [searchParams] = useSearchParams()
  const [dialogState, setDialogState] = useState<{
    isOpen: boolean
    showManualSetup?: boolean
    onExitCallback?: (success: boolean | undefined) => void
  }>({
    isOpen: searchParams.get("connect") !== null,
  })

  const openMergeDialog = useCallback(
    (options?: {
      showManualSetup?: boolean
      onExitCallback?: (success: boolean | undefined) => void
    }) => {
      setDialogState({
        isOpen: true,
        ...options,
      })
    },
    []
  )

  const closeMergeDialog = useCallback(() => {
    setDialogState((prev) => ({
      ...prev,
      isOpen: false,
    }))
  }, [])

  return (
    <MergeContext.Provider value={{ openMergeDialog, closeMergeDialog }}>
      <ManualHrisDataProvider>
        <MergeDialog
          open={dialogState.isOpen}
          onOpenChange={(open) =>
            setDialogState((prev) => ({ ...prev, isOpen: open }))
          }
          showManualSetup={dialogState.showManualSetup}
          onExitCallback={dialogState.onExitCallback}
        />
        {children}
      </ManualHrisDataProvider>
    </MergeContext.Provider>
  )
}
