import { Dialog, DialogContent } from "~/ui/dialog"
import { Connect } from "./steps/Connect"
import { ManualHrisBackend } from "./steps/ManualHrisBackend"
import { ManualCompanySize } from "./steps/ManualCompanySize"
import { ManualTurnover } from "./steps/ManualTurnover"
import { ManualCostPerEmployee } from "./steps/ManualCostPerEmployee"
import { ManualRetention } from "./steps/ManualRetention"
import { ManualTenure } from "./steps/ManualTenure"
import { Success } from "./steps/Success"
import { useManualHrisDataContext } from "~/merge/manualData"

export enum Steps {
  Connect = 0,
  ManualHrisBackend = 1,
  ManualCompanySize = 2,
  ManualTurnover = 3,
  ManualCostPerEmployee = 4,
  ManualRetention = 5,
  ManualTenure = 6,
  Success = 7,
}

export const STEPS = [
  Steps.Connect,
  Steps.ManualHrisBackend,
  Steps.ManualCompanySize,
  Steps.ManualTurnover,
  Steps.ManualCostPerEmployee,
  Steps.ManualRetention,
  Steps.ManualTenure,
  Steps.Success,
]

export const MergeDialog = ({
  open,
  onOpenChange,
  showManualSetup = true,
  onExitCallback,
}: {
  open: boolean
  onOpenChange: (open: boolean) => void
  showManualSetup?: boolean
  onExitCallback?: (success: boolean | undefined) => void
}) => {
  const { currentStep } = useManualHrisDataContext()

  const getDialogContent = () => {
    switch (currentStep) {
      case Steps.Connect:
        return (
          <Connect
            onExitCallback={onExitCallback}
            showManualSetup={showManualSetup}
          />
        )
      case Steps.ManualHrisBackend:
        return <ManualHrisBackend />
      case Steps.ManualCompanySize:
        return <ManualCompanySize />
      case Steps.ManualTurnover:
        return <ManualTurnover />
      case Steps.ManualCostPerEmployee:
        return <ManualCostPerEmployee />
      case Steps.ManualRetention:
        return <ManualRetention />
      case Steps.ManualTenure:
        return <ManualTenure />
      case Steps.Success:
        return <Success />
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className="w-2/3 p-0"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
        noCloseButton={currentStep === Steps.Success}
      >
        {getDialogContent()}
      </DialogContent>
    </Dialog>
  )
}
