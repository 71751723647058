import { useQuery } from "@apollo/client"
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  Tier,
  TierIntervalEnum,
  TierLevelEnum,
  TiersQuery,
  User_CurrentUserFragment,
} from "~/__generated__/graphql"

export type TierFeature =
  | "canInitiateDms"
  | "canRespondToDms"
  | "canUseIntroductions"
  | "canViewPremiumArticles"
  | "canViewCourses"
  | "canViewProEvents"
  | "canAttendPrivateEvents"
  | "canViewSocialMediaLinks"

const TiersContext = createContext<{
  tiers: TiersQuery["tiers"]
  features: Record<string, TierFeature[]>
  formatTierName: (
    tierIdOrTier?: string | Pick<Tier, "id"> | null,
    interval?: TierIntervalEnum | null,
    withMembership?: boolean
  ) => string
  getFeatureDifferences: (
    currentTier: Pick<Tier, "level">,
    targetTier: Pick<Tier, "level">
  ) => TierFeature[]
  tierByPriceId: (priceId: string) => TiersQuery["tiers"][0] | undefined
  freeTier: TiersQuery["tiers"][0] | undefined
  plusTier: TiersQuery["tiers"][0] | undefined
  proTier: TiersQuery["tiers"][0] | undefined
  changeTierCta: (
    currentUser?: User_CurrentUserFragment,
    targetTier?: Pick<Tier, "id" | "position" | "level" | "name"> | null,
    targetInterval?: TierIntervalEnum | null
  ) => string
} | null>(null)

export const useTiers = () => {
  const context = useContext(TiersContext)
  invariant(context, "useTiers must be used within a TiersProvider")
  return context
}

interface TiersProviderProps extends PropsWithChildren<{}> {}

export const TiersProvider = ({ children }: TiersProviderProps) => {
  const { data, loading } = useQuery(TIERS_QUERY_DOCUMENT)
  const tiers = useMemo(() => data?.tiers || [], [data])
  const features = useMemo(() => {
    if (!tiers) {
      return {} as Record<string, TierFeature[]>
    }

    return tiers.reduce(
      (acc, tier) => {
        acc[tier.level] = Object.keys(tier.permissions).filter((key) => {
          return !!tier.permissions[key]
        }) as TierFeature[]

        return acc
      },
      {} as Record<string, TierFeature[]>
    )
  }, [tiers])

  const formatTierName = useCallback(
    (
      tierIdOrTier?: string | Pick<Tier, "id"> | null,
      interval?: TierIntervalEnum | null,
      withMembership = true
    ) => {
      if (!tierIdOrTier) return ""
      const tier = tiers.find(
        (t) =>
          t.id ===
          (typeof tierIdOrTier === "string" ? tierIdOrTier : tierIdOrTier.id)
      )
      if (!tier) {
        return ""
      }

      if (tier.quarterlyStripePriceId && tier.yearlyStripePriceId) {
        const formattedInterval =
          interval === TierIntervalEnum.Quarter ? "Quarterly" : "Annual"
        return withMembership
          ? `${tier.name} Membership (${formattedInterval})`
          : `${tier.name} ${formattedInterval}`
      } else {
        return withMembership ? `${tier.name} Membership` : `${tier.name}`
      }
    },
    [tiers]
  )

  const getFeatureDifferences = useCallback(
    (currentTier: Pick<Tier, "level">, targetTier: Pick<Tier, "level">) => {
      const currentFeatures = features[currentTier.level] || []
      const targetFeatures = features[targetTier.level] || []
      const differences = [] as TierFeature[]

      for (const feature of currentFeatures) {
        if (!targetFeatures.includes(feature)) {
          differences.push(feature)
        }
      }
      return differences
    },
    [features]
  )

  const tierByPriceId = useCallback(
    (priceId: string) => {
      return tiers.find(
        (tier) =>
          tier.yearlyStripePriceId === priceId ||
          tier.quarterlyStripePriceId === priceId
      )
    },
    [tiers]
  )

  const freeTier = useMemo(
    () => tiers.find((t) => t.level === TierLevelEnum.Free),
    [tiers]
  )
  const plusTier = useMemo(
    () => tiers.find((t) => t.level === TierLevelEnum.Plus),
    [tiers]
  )
  const proTier = useMemo(
    () => tiers.find((t) => t.level === TierLevelEnum.Pro),
    [tiers]
  )

  const changeTierCta = useCallback(
    (
      currentUser?: User_CurrentUserFragment,
      targetTier?: Pick<Tier, "id" | "position" | "level" | "name"> | null,
      targetInterval?: TierIntervalEnum | null
    ) => {
      if (!targetTier) return "Select a membership level"
      const targetTierName = targetTier.name
      if (!currentUser?.activeStripeSubscription)
        return `Subscribe to ${targetTierName}`
      if (currentUser.tier?.level === targetTier?.level) {
        if (currentUser.tierInterval === targetInterval) {
          return `Stay on your ${targetTierName}`
        } else {
          return `Switch to ${targetTierName}`
        }
      }

      if (targetTier.position > (currentUser.tier?.position || 0)) {
        return `Upgrade to ${targetTierName}`
      } else {
        return `Downgrade to ${targetTierName}`
      }
    },
    []
  )

  if (loading) {
    return null
  }

  return (
    <TiersContext.Provider
      value={{
        tiers,
        features,
        formatTierName,
        getFeatureDifferences,
        tierByPriceId,
        freeTier,
        plusTier,
        proTier,
        changeTierCta,
      }}
    >
      {children}
    </TiersContext.Provider>
  )
}

const TIERS_QUERY_DOCUMENT = gql(`
  query Tiers {
    tiers {
      id
      name
      level
      stripeProductId
      quarterlyStripePriceId
      yearlyStripePriceId
      position
      active
      deprecated

      permissions

      stripeProduct {
        id
        name
      }

      quarterlyStripePrice {
        unitAmount
        currency
      }

      yearlyStripePrice {
        unitAmount
        currency
      }
    }
  }
`)
